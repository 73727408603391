<template>
  <div class="write-page-heng-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImgObj"
      :strokeImgFill="strokeImgFill"
      :strokePinyin="strokePinyin"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "piě",
      strokeImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-06-pie/lesson-06-pie-grey.svg"),
      strokeImgFill: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-06-pie/lesson-06-pie-red.svg"),
      bgImgList: [
         {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-1.svg"),
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-2.svg"),
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-3.svg"),
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-4.svg"),
        },
      ],
      flagImgObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/icon-ice-cream-1.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/icon-ice-cream-2.svg"),
        pinyin: "piě",
        tipImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-06-pie/lesson-06-pie.gif"),
      },
    };
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
