<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/park.svg"),
      gameIndex: 5,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-kite.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-kite-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-merrygoround.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-merrygoround-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-ship.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-ship-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-skateboard.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-skateboard-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-swings.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/stroke-swings-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/na-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-2/na-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
