<template>
  <!-- layout-container lesson 6 -->
  <div class="lesson-six-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
// import SyllabusPageB from "@/components/Course/CoursePage/SyllabusPageB";
import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import RocketGame from "./4_RocketGame.vue";
import DragGamePageOne from "./5_DragGameOne.vue";
import DragGamePageTwo from "./6_DragGameTwo.vue";
import RaceGame from "./7_RaceGame.vue";
import MoveGame from "./8_MoveGame.vue";
import SpeakingPage from "./9_SpeakingPage.vue";
import RecognitionPageOne from "./10_RecognitionPage_pie.vue";
import PracticePageOne from "./11_PracticePageOne_pie.vue";
import PracticePageTwo from "./12_PracticePageTwo_pie.vue";
import writePage_pie from "./13_writePage_pie.vue";
import RecognitionPageTwo from "./14_RecognitionPage_na.vue";
import PracticePageThree from "./15_PracticePageThree_na.vue";
import PracticePageFour from "./16_PracticePageFour_na.vue";
import writePage_na from "./17_writePage_na.vue";
import PracticeGame from "./18_PracticeGame.vue";
import WriteGameOne from "./19_WriteGame_shi.vue";
import WriteGameTwo from "./20_WriteGame_ba.vue";
import LearnReview from "./21_LearnReview.vue";
import RankMapPageTwo from "./22_RankMapPageTwo.vue";
import ByePage from "./23_ByePage.vue";
export default {
  name: "Lesson6",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    RocketGame,
    DragGamePageOne,
    DragGamePageTwo,
    RaceGame,
    MoveGame,
    SpeakingPage,
    RecognitionPageOne,
    PracticePageOne,
    PracticePageTwo,
    writePage_pie,
    RecognitionPageTwo,
    PracticePageThree,
    PracticePageFour,
    writePage_na,
    PracticeGame,
    WriteGameOne,
    WriteGameTwo,
    LearnReview,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        RocketGame,
        DragGamePageOne,
        DragGamePageTwo,
        RaceGame,
        MoveGame,
        SpeakingPage,
        RecognitionPageOne,
        PracticePageOne,
        PracticePageTwo,
        writePage_pie,
        RecognitionPageTwo,
        PracticePageThree,
        PracticePageFour,
        writePage_na,
        PracticeGame,
        WriteGameOne,
        WriteGameTwo,
        LearnReview,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson-six-container {
  width: 100%;
  height: 100%;
  cursor: default;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
