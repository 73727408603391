<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stage.svg"),
      gameIndex: 4,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-beard.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-beard-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-cape.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-cape-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-curtain.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-curtain-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-dress.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-dress-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-hand.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/stroke-hand-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/pie-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-06-1/pie-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>