<template>
  <div class="write-page-shu-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImgObj"
      :strokePinyin="strokePinyin"
      :strokeImgFill="strokeImgFill"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "nà",
      strokeImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-06-na/lesson-06-na-grey.svg"),
      strokeImgFill: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-06-na/lesson-06-na-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-1.svg")
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-2.svg")
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-3.svg")
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/ice-cream-4.svg")
        }
      ],
      flagImgObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/icon-ice-cream-1.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/icon-ice-cream-2.svg"),
        pinyin: "nà",
        tipImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-06-na/lesson-06-na.gif"),
      },
    };
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-shu-container {
  width: 100%;
  height: 100%;
}
</style>
