<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :shadowImg = "shadowImg"
      :headerBgImg = 'headerBgImg'
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg:{
        defaultImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-1.svg"),
        firstImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-2.svg"),
        secondImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-3.svg"),
        thirdImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-4.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/ice-cube.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/na-white.svg"),
      strokePinyin: "nà",
      gameIndex: 4,     
      lightIndex: [1, 2, 3],
      hanziStrokeInfo: [
        {
          pinyin: "tiān",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/tian-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/tian-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "bà",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/ba-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/ba-1.svg"),
              isShow: false,
              index: 2,
            },
            // {
            //   bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/ba-1.svg"),
            //   isShow: false,
            //   index: 3,
            // },
          ],
        },
        {
          pinyin: "jiā",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/jia-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-2/jia-2.svg"),
              isShow: false,
              index: 3,
            },
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>